import { template as template_b07b9a400c2f448aa0cc01167174bf9b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_b07b9a400c2f448aa0cc01167174bf9b(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
