import { template as template_2ccad9a345904505a2aa00a30fda6244 } from "@ember/template-compiler";
const FKLabel = template_2ccad9a345904505a2aa00a30fda6244(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
